.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  margin-top: 2em;
  width: 38%;
}

.center-login-title {
  margin-top: 2em;
}

.center-loginImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  margin-top: 1em;
  width: 18%;
}

.center-loadingImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
  width: 40%;
}

.container {
  background: #d3d1d1;
  margin: auto;
  width: 80vw;
}

.branches-container {
  margin-top: 2em;
}

.commits-container {
  margin-top: 2em;
}

.errorComponent-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
  width: 20%;
}

.searchRepo-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  margin-top: 2em;
}

.searchRepo-input {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  margin-top: 2em;
  width: 10em;
}